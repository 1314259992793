<template>
  <form
    action="#"
    method="post"
    @submit="onSaveConduiteAttachment"
  >
    <b-row
      v-for="(state, index) in conduite_attachment"
      v-bind:key="index"
    >
      <b-col md="12">
        <div
          v-if="conduite_attachment.length > 1 && index > 0"
          class="d-flex w-100 px-4 my-1 justify-content-end cursor-pointer position-absolute top-0"
          @click="deleteConduiteAttachmentForm(index)"
        >
          <i class="fa fa-trash text-danger"></i>
        </div>
        <b-form-group label="Nama Dokumen" label-for="notes">
          <b-form-input
            id="notes"
            name="nama_document"
            v-model="state.nama_document"
            placeholder="Text type"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Date" label-for="notes">
          <b-form-input
            id="notes"
            name="date"
            type="date"
            v-model="state.date"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Attachment"
          label-for="attachment"
        >
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="validatedCustomFile"
              accept="application/*"
              @change="(event) => prefiewFiles(event, state)"
            />
            <small class="text-muted"> File Type * :
              <b-badge variant="danger">jpeg,png,jpg,pdf,xlx,xlsx,docx,doc</b-badge>
                <br />
                File CapaKota * :
              <b-badge variant="danger">3 MB / File</b-badge>
            </small>

            <label
              class="custom-file-label"
              for="validatedCustomFile"
              v-if="!state.file"
              >Browse file...</label
            ><label
              class="custom-file-label"
              for="validatedCustomFile"
              v-else
              >{{ state.label_file }}</label
            >
            <!-- this.conduite_attachment -->
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="12">
        <b-button
          variant="success"
          class="mt-1"
          @click="addConduiteAttachmentForm"
          ><i class="fa fa-plus"></i> ADD FORM</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col :md="'6'">
        <b-button
          variant="secondary"
          block
          @click="$bvModal.hide('conduite-attachments')"
          >Close</b-button
        >
      </b-col>
      <b-col md="6">
        <b-button
          variant="primary"
          block
          type="submit"
          :disabled="saving"
          ><i class="fa fa-file-signature" v-if="!saving"></i>
          <span v-if="!saving">SUBMIT</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm"
          />
        </b-button>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name:'ContentConduiteAttachment',
  data() {
    return {
      saving:false,

      conduite_attachment: [
        {
          label_file: '',
          file: '',
          date: '',
          nama_document: '',
        },
      ],
    }
  },
  props: {
    fetchAttachments:{
      type: Function,
      default: () => {},
    }
  },
  computed: {
   
  },
  mounted() {},
  methods: {
    ...crewingActions,
    prefiewFiles: function (event, state) {
      const input = event.target

      if (input.files && input.files[0]) {
        state.label_file = input.files[0].name
        state.file = input.files[0]
      }
    },
    
    async onSaveConduiteAttachment(e) {
      e.preventDefault()
      this.saving = true

      let tempConduiteAttachment = []
      this.conduite_attachment.map((item) => {
        let payload = {
          file: item.file,
          nama_document: item.nama_document,
          date: item.date ? moment(item.date).format('YYYY-MM-DD') : null,
          type: 'interview',
          emp_crewing_id: Number(this.$route.query.id),
          candidate_id: Number(this.$route.query.id),
        }
        if(this.$route.query.user === 'crew') delete payload.candidate_id
        if(this.$route.query.user === 'candidate') delete payload.emp_crewing_id
        tempConduiteAttachment.push(payload)
      })

      let formData = new FormData()

      formData.append(`type`, this.$route.query.user)
      tempConduiteAttachment.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `condite_interview_upload[${index}][${property}]`,
            item[property]
          )
        }
      })

      const { status, data } = await this.setConduiteInterviewAttachments({
        data: formData,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `${data}`, 'error')
        return
      }

      this.$swal(`Success!`, `${data}`, 'success')

      this.saving = false
      this.conduite_attachment = [{
        label_file: '',
        file: '',
        date:null,
        nama_document: '',
      }]
      this.$nextTick(() => {
        this.$bvModal.hide('conduite-attachments')
      })
      this.fetchAttachments()
    },

    // Medical Checkup Adding and Removing Forms
    addConduiteAttachmentForm() {
      this.conduite_attachment.push({
        label_file: '',
        file: '',
        date:null,
        nama_document: '',
      })
    },
    deleteConduiteAttachmentForm(index) {
      this.conduite_attachment.splice(index, 1)
    },
  },
}
</script>