<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom d-flex align-items-center justify-content-between">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="homeURL">
            <img :src="logo" class="img-fluid" alt="logo">
          </router-link>
        </div>
      </div>
      <small class="text-muted ml-3 text-start">copyright © {{ moment(new Date()).format('YYYY') }} transenergilogistik.com created by&nbsp; <br /><strong> IT Department</strong></small>
      <div class="iq-menu-horizontal" v-if="horizontal">
        <div class="iq-sidebar-menu">
          <List :items="items" class="d-flex"></List>
        </div>
      </div>

      <div class="align-self-center">
        <div class="wrapper-menu" :class="{'open' : isMini}" @click="miniSidebar">
          <div class="main-circle"><small><i class="fa fa-bars"></i></small></div>
          <div class="hover-circle"><small><i class="fa fa-eye-slash" ></i></small></div>
        </div>
      </div>

      <nav class="p-0 d-flex">
        <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import List from '../menus/ListStyle1'
import moment from 'moment'
import SideBarItems from '../../../FackApi/json/SideBar'
export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard.home' }) },
    title: { type: String, default: 'Dashboard' },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    horizontal: { type: Boolean, default: false },
    items: { type: Array },
    toggleButton: { type: Boolean, default: true }
  },
  mounted () {
    document.addEventListener('click', this.closeSearch, true)
  },
  components: {
    List
  },
  computed: {
    isMini () {
      return this.$store.getters['Setting/miniSidebarState']
    }
  },
  data () {
    return {
      sidebar: SideBarItems,
      globalSearch: '',
      showSearch: false,
      showMenu: false
    }
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.important-left {
  right: 0px !important;
  left: 12px;
}
</style>