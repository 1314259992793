var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSaveInterviewAttachment
    }
  }, [_vm._l(_vm.interview_attachment, function (state, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_vm.interview_attachment.length > 1 && index > 0 ? _c('div', {
      staticClass: "d-flex w-100 px-4 my-1 justify-content-end cursor-pointer position-absolute top-0",
      on: {
        "click": function click($event) {
          return _vm.deleteInterviewAttachmentForm(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash text-danger"
    })]) : _vm._e(), _c('b-form-group', {
      attrs: {
        "label": "Nama Dokumen",
        "label-for": "notes"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "notes",
        "name": "nama_document",
        "placeholder": "Text type"
      },
      model: {
        value: state.nama_document,
        callback: function callback($$v) {
          _vm.$set(state, "nama_document", $$v);
        },
        expression: "state.nama_document"
      }
    })], 1), _c('b-form-group', {
      attrs: {
        "label": "Date",
        "label-for": "notes"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "notes",
        "name": "date",
        "type": "date"
      },
      model: {
        value: state.date,
        callback: function callback($$v) {
          _vm.$set(state, "date", $$v);
        },
        expression: "state.date"
      }
    })], 1), _c('b-form-group', {
      attrs: {
        "label": "Attachment",
        "label-for": "attachment"
      }
    }, [_c('div', {
      staticClass: "custom-file"
    }, [_c('input', {
      staticClass: "custom-file-input",
      attrs: {
        "type": "file",
        "id": "validatedCustomFile",
        "accept": "application/*"
      },
      on: {
        "change": function change(event) {
          return _vm.prefiewFiles(event, state);
        }
      }
    }), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(" File Type * : "), _c('b-badge', {
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v("jpeg,png,jpg,pdf,xlx,xlsx,docx,doc")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v("3 MB / File")])], 1), !state.file ? _c('label', {
      staticClass: "custom-file-label",
      attrs: {
        "for": "validatedCustomFile"
      }
    }, [_vm._v("Browse file...")]) : _c('label', {
      staticClass: "custom-file-label",
      attrs: {
        "for": "validatedCustomFile"
      }
    }, [_vm._v(_vm._s(state.label_file))])])])], 1)], 1);
  }), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.addInterviewAttachmentForm
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" ADD FORM")])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": '6'
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "secondary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('interview-attachments');
      }
    }
  }, [_vm._v("Close")])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "type": "submit",
      "disabled": _vm.saving
    }
  }, [!_vm.saving ? _c('i', {
    staticClass: "fa fa-file-signature"
  }) : _vm._e(), !_vm.saving ? _c('span', [_vm._v("SUBMIT")]) : _vm._e(), _vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }