var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-top-navbar"
  }, [_c('div', {
    staticClass: "iq-navbar-custom d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "iq-sidebar-logo"
  }, [_c('div', {
    staticClass: "top-logo"
  }, [_c('router-link', {
    attrs: {
      "to": _vm.homeURL
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.logo,
      "alt": "logo"
    }
  })])], 1)]), _c('small', {
    staticClass: "text-muted ml-3 text-start"
  }, [_vm._v("copyright © " + _vm._s(_vm.moment(new Date()).format('YYYY')) + " transenergilogistik.com created by  "), _c('br'), _c('strong', [_vm._v(" IT Department")])]), _vm.horizontal ? _c('div', {
    staticClass: "iq-menu-horizontal"
  }, [_c('div', {
    staticClass: "iq-sidebar-menu"
  }, [_c('List', {
    staticClass: "d-flex",
    attrs: {
      "items": _vm.items
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "align-self-center"
  }, [_c('div', {
    staticClass: "wrapper-menu",
    class: {
      'open': _vm.isMini
    },
    on: {
      "click": _vm.miniSidebar
    }
  }, [_vm._m(0), _vm._m(1)])]), _c('nav', {
    staticClass: "p-0 d-flex"
  }, [_vm._t("right")], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-circle"
  }, [_c('small', [_c('i', {
    staticClass: "fa fa-bars"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hover-circle"
  }, [_c('small', [_c('i', {
    staticClass: "fa fa-eye-slash"
  })])]);

}]

export { render, staticRenderFns }